// Standard responsive grid with gaps, different by breakpoint.

@mixin grid-standard {
  display: grid;
  grid-template-columns: repeat(var(--standard-cols), minmax(0, 1fr));
  @include grid-standard-col-gaps;
}

@mixin grid-standard-col-gaps {
  column-gap: var(--standard-gap);
}

@mixin fillGrid {
  grid-row-start: 1;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: -1;
}
