@import '../../scss/components_base';

.positioner {
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  z-index: 9;
  display: none;
  @include md {
    display: block;
  }
}

.iconHolder {
  position: absolute;
  z-index: 120;
  top: 0;
  left: 0;
}
.iconHolderVisible {
  display: block;
}
.icon {
  position: relative;
  opacity: 0;
}
.iconIsMinus {
  .iconLine {
    width: 30px;
    &:nth-child(1) {
      transform: rotate(0deg);
    }
    &:nth-child(2) {
      transform: rotate(180deg);
    }
  }
}
.iconIsPlus {
  .iconLine {
    &:nth-child(1) {
      transform: rotate(90deg);
    }
    &:nth-child(2) {
      transform: rotate(0deg);
    }
  }
}

.iconLine {
  width: 15px;
  height: 2px;
  // display: block;
  position: absolute;
  // top: 0;
  // left: 0;
  background: white;
  transform: rotate(90deg);
  transition: all 0.4s;
  transform-origin: center;
}
