@use 'sass:math';

@mixin vw-font-size($px-size, $min-px-size: null, $max-px-size: null, $design-width) {
  $unitless-vw-size: math.div($px-size, $design-width) * 100;
  $scaled-size: #{$unitless-vw-size + 'vw'};

  font-size: clamp($min-px-size, $scaled-size, $max-px-size);
}
@mixin vh-font-size($px-size, $min-px-size: null, $max-px-size: null, $design-height) {
  $unitless-vh-size: math.div($px-size, $design-height) * 100;
  $scaled-size: #{$unitless-vh-size + 'vh'};

  font-size: clamp($min-px-size, $scaled-size, $max-px-size);
}

@mixin intro {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;

  @include md {
    font-style: normal;
    font-weight: 400;
    font-size: rem(34);
    line-height: 1.41;
    letter-spacing: 2px;
  }
}

@mixin body {
  font-style: normal;
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  @include tabletOnly {
    font-size: 47px;
    line-height: 1.18;
  }
  @include md {
    font-size: rem(47);
    line-height: 1.18;
  }
}

@mixin content {
  @include body();
  // @include md {
  //   @media (max-height: 1100px) {
  //     font-size: rem(31);
  //   }
  // }
}

@mixin heading-medium {
  font-style: normal;
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 60px;
  line-height: 0.879;
  font-feature-settings: 'tnum' on, 'lnum' on;
  @include tabletOnly {
    font-size: 100px;
    line-height: 0.879;
  }
  @include md {
    font-size: rem(120);
    line-height: 0.879;
  }
}
@mixin slide-title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  // line-height: 1.25;
  line-height: 1;
  @include tabletOnly {
    font-size: 47px;
  }
  @include md {
    font-size: rem(47);
    // line-height: 1.2;
  }
}
@mixin numerals {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 47px;
  line-height: 1.15;
  @include tabletOnly {
    font-size: 47px;
    line-height: 1.15;
  }
  @include md {
    font-size: rem(47);
    line-height: 1.15;
  }
}
