$breakpoint-sm: 420px;
$breakpoint-md: 1124px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1300px;
$breakpoint-xxl: 1440px;

@mixin sm {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}
// we used this for TurnerDuckworth special case for a few settings
@mixin customTablet {
  @media (min-width: 768px) and (max-width: 1250px) {
    @content;
  }
}
@mixin tabletOnly {
  @media (min-width: 769px) and (max-width: 1113px) {
    @content;
  }
}

@mixin md {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}
@mixin lg {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
@mixin xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}
