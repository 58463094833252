@use 'sass:math';

@mixin pill-button {
  cursor: pointer;
  display: block;
  text-align: center;
  height: rem(60);
  text-decoration: none;
  background: transparent;
  color: var(--color-dark);
  border: 1px solid var(--color-dark);
  border-radius: 33px;
  padding-left: rem(25);
  padding-right: rem(25);
  // body large has a 19px size
  // padding-top: math.div(16px, 19px) * 1em;

  &:hover {
    background: transparent;
  }
  &:active {
    background: transparent;
  }
  &:visited {
  }

  @include md {
    // background: white;
    min-width: rem(135);
    font-size: rem(19);
    &:hover {
    }
    &:active {
      // color: white;
      // background: black;
    }
  }
}

@mixin btn {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  border: 0;

  font-size: inherit;
  display: inline-block;
  text-align: left;

  background: none;
  color: inherit;
  margin: 0;
  padding: 0;

  &:disabled {
    cursor: default;
  }

  &:hover {
    background: none;
  }
}
