@import '../../scss/components_base';

.positioner {
  width: 100%;
  height: 100%;

  position: relative;
}

.positionerForPortrait {
  @include md {
    display: flex;
    max-height: calc(var(--vh) - var(--page-gutter) * 2);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // background: #333c21;
   
  }
}

.positionerHasFitHeightItem {
  @include md {

  }
}

.cropper {
  width: 100%;
  height: 100%;
  position: relative;
}
.cropperForPortrait {
  transform-origin: center;
  transition: 0.5s;
}

.cropperFitHeight {
  @include md {
 
  }
}
