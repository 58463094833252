@import '../../scss/components_base';

@keyframes pauseSnap {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mediaContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #e4e4e4;
}

.grid {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  @include md {
    animation-duration: var(--pauseDelay);
    animation-name: pauseSnap;
  }
}
.multiGrid {
  display: grid;
  position: relative;

  width: 100%;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  @include md {
    animation-duration: var(--pauseDelay);
    animation-name: pauseSnap;
  }
}

.multiGridPadding {
  padding: var(--page-gutter);
  @include md {
    padding: rem(50);
  }
}

.full {
  grid-template-areas:
    't t t t'
    't t t t';
}
.landscapeTop {
  grid-template-areas:
    't t t t'
    '. . . .';
}
.landscapeBottom {
  grid-template-areas:
    '. . . .'
    't t t t';
}
.squareCrop {
  grid-template-areas: 't t t t';
  padding: rem(140);

  align-content: center;
}
.square {
  grid-template-areas: 't t t t';
  // padding: rem(140);

  align-content: center;
}
///
.singleItem {
  grid-area: t;
  position: relative;
}
.singleItem__full {
  grid-area: t;
}
.singleItem__squareCrop {
  grid-template-areas: 't t t t';
  // padding: rem(140);
  justify-self: center;
  align-self: center;
  align-content: center;

  width: calc(100% - rem(140) * 2);
  aspect-ratio: 1;
}

.singleItem__square {
  grid-template-areas: 't t t t';
  // padding: rem(140);
  justify-self: center;
  align-self: center;
  align-content: center;
  width: 100%;
  width: calc(100% - rem(50) * 2);
  aspect-ratio: 1;
}

.multiItem {
  grid-column-start: 1;
  grid-column-end: -1;
}
.multiItem__full {
  grid-column-start: 1;
  grid-column-end: -1;
  height: 100%;
}
.multiItem__landscapeTop {
  grid-row-start: 1;
  grid-row-end: 1;
  // margin: calc(var(--page-gutter));
  // margin-bottom: calc(var(--page-gutter) / 2);
}
.multiItem__landscapeBottom {
  grid-row-start: 2;
  grid-row-end: 2;
}

.multiItem__squareTopRight {
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 1;
  grid-row-end: 1;
  align-self: flex-start;
  justify-self: flex-end;

  width: 100px;
  height: 100px;
  // width: 15vw;
  // height: 15vw;
  padding-bottom: var(--page-gutter);

  @include md {
    width: rem(300);
    height: rem(300);
  }
}
.multiItem__squareBottomLeft {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  align-self: flex-end;
  padding-top: var(--page-gutter);
  width: 200px;
  height: 200px;
  @include md {
    width: rem(300);
    height: rem(300);
  }
}
.doubleLandscapeTop {
  margin: calc(var(--page-gutter));
  margin-bottom: calc(var(--page-gutter) / 2);
}
.doubleLandscapeBottom {
  margin: calc(var(--page-gutter));
  margin-top: calc(var(--page-gutter) / 2);
}
