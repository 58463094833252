@import '../../../../scss/components_base';

.mobileImage,
.desktopImage,
.desktopImageCanSwap {
  // opacity: 0;
  // transition: opacity 0s;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.imageLoaded {
  // opacity: 1;
  user-select: none;
}
.desktopImageCanSwap {
  display: none !important;
}
@media (orientation: landscape) {
  .desktopImageCanSwap {
    display: block !important;
    user-select: none;
  }
  .mobileImage {
    display: none !important;
    user-select: none;
  }
}
