@import '../../scss/components_base';

.page {
  width: 100%;
  height: var(--vh);
  position: relative;
}

.hideCursor {
  cursor: none;
}
.container {
  // @include gutter;
  width: 100%;
  height: 100%; //calc(100% - var(--page-gutter));
  @include md {
    min-height: 100%;
    height: auto;
  }
}

.title {
  @include slide-title;
  position: relative;
  z-index: 2;

  height: var(--page-gutter);
  margin-bottom: calc(var(--page-gutter) * 2);
  flex: 1;
}
.mobileInfoFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: var(--page-gutter);
  z-index: 4;

  background: white;
  height: calc(4 * var(--page-gutter));
  @include tabletOnly {
    display: block;
  }
  @include md {
    display: none;
  }
}
.contentToggle {
  @include btn;
  // font-size: 20px;
  line-height: 1;
  margin-top: calc(var(--page-gutter));
  @include tabletOnly {
    // margin-top: 0;
    // margin-top: 25px;
  }
}
.toggleActive {
  border-bottom: 1px solid black;
}
.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  padding-top: var(--mobile-header-height);
  padding-bottom: var(--mobile-header-height);
  @include md {
    @include grid-standard;
    // background: white;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.titleContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: white;
  width: 100%;
  height: var(--mobile-header-height);

  padding-top: var(--page-gutter);
  padding-left: var(--page-gutter);
  @include md {
    position: fixed;
    top: auto;
    height: auto;
    bottom: 0;
    left: 50%;
    display: flex;
    flex-direction: row;
    background: white;
    width: 50%;
    padding-top: var(--page-gutter);
    padding-left: var(--page-gutter);
  }
}
.titleGradient {
  display: none;
  @include md {
    display: block;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    // background-color: red;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 8%,
      rgba(255, 255, 255, 0) 100%
    );
    top: calc(var(--mobile-header-height) * -2);
    width: 100vw;
    height: calc(var(--mobile-header-height) * 2);
  }
}
.gridImages {
  background: white;
  border: var(--page-gutter) solid white;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 1;
  grid-row-end: -1;
  z-index: 2;
  // remove border height!
  // margin-top: calc(var(--mobile-header-height) - var(--page-gutter));
  // margin-bottom: calc(100px - var(--page-gutter));

  @include md {
    position: fixed;
    margin-top: 0;
    top: 0;
    width: 50%;
    height: 100vh;
    z-index: 6;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
.gridImageSlider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include md {
    overflow: visible;
  }
}
.gridContentSlider {
  @include content();
  max-width: 80vh;
  position: relative;
  flex: 1;
  hyphens: none;
  @include md {
    padding-right: rem(100);
    padding-bottom: rem(100);
  }
}
.gridImageSlide {
  @include md {
  }
  position: absolute;
  top: 0;
  left: 0%;
  right: 0;
  bottom: 0;
  z-index: 0;
  visibility: hidden;
}
.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.gridImageSlideSelected {
  visibility: visible;
  z-index: 1;
}
.gridContentSlide {
  width: 100%;

  display: none;
  :global {
    .heading-large {
      @include heading-medium;
    }
    p {
      margin-top: 15px;
      margin-bottom: 35px;
      &:first-child {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  @include md {
    :global {
      .heading-large {
        @include heading-medium;
      }
      p {
        margin-top: rem(40);
        margin-bottom: rem(40);
        &:first-child {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
.gridContentSlideSelected {
  display: block;
}
.gridContent {
  @include gutter-double;
  display: none;
  padding-left: var(--page-gutter);
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 1;
  grid-row-end: -1;
  // margin-top: calc(var(--mobile-header-height) - var(--page-gutter));
  padding-bottom: calc(4 * var(--page-gutter));

  z-index: 3;
  background: white;

  @include md {
    margin-top: 0;
    // padding: 0;
    grid-column-start: 9;
    grid-column-end: -1;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: none;
    z-index: auto;
  }
}

.gridContentIsVisible {
  display: block;
  @include md {
    display: flex;
  }
}

.pageNumber {
  @include numerals;
  display: none;
  grid-column-start: 1;
  grid-row-start: 1;
  position: relative;
  z-index: 2;
  transition-property: transform, opacity;
  transition-duration: 0.1s;
  pointer-events: none;
  user-select: none;
  color: white;

  @include md {
    @include gutter-double;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
  }
}

.pageNumberHide {
  opacity: 0;
  transform: translateX(-25%);
}
