// Misc mixins

@mixin visuallyHidden {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
}

// Page initially loads with 'no-js' class on the body.
// As soon as JS loads (via a script in the header),
// it should replace that class with 'js'
@mixin no-js-hide {
  .no-js & {
    display: none !important;
  }
}
@mixin js-hide {
  .js & {
    display: none !important;
  }
}

@mixin gutter {
  padding-left: var(--page-gutter);
  padding-right: var(--page-gutter);
  padding-top: var(--page-gutter);
  padding-bottom: var(--page-gutter);
}
@mixin gutter-double {
  padding-left: calc(var(--page-gutter) * 2);
  padding-right: calc(var(--page-gutter) * 2);
  padding-top: calc(var(--page-gutter) * 2);
  padding-bottom: calc(var(--page-gutter) * 2);
}
@mixin list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}
